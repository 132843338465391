var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Select } from 'antd';
import { CaretDownOutlined, RightOutlined, LeftOutlined } from '@ant-design/icons';
function Dropdown(_a) {
    var placeholder = _a.placeholder, data = _a.data, value = _a.value, onChange = _a.onChange, searchable = _a.searchable, isGroupData = _a.isGroupData, options = _a.options, title = _a.title, width = _a.width, paddingY = _a.paddingY, isDisabled = _a.isDisabled, isPaginated = _a.isPaginated, loadProds = _a.loadProds, currIndex = _a.currIndex, maxPageIndex = _a.maxPageIndex, isSearchBoxVisible = _a.isSearchBoxVisible, searchBoxOnChange = _a.searchBoxOnChange, onSelect = _a.onSelect, onClear = _a.onClear, _b = _a.allowClear, allowClear = _b === void 0 ? false : _b, _c = _a.dropdownMatchSelectWidth, dropdownMatchSelectWidth = _c === void 0 ? true : _c, _d = _a.dropdownWidth, dropdownWidth = _d === void 0 ? 0 : _d;
    if (searchable) {
        return (_jsxs("div", __assign({ style: { width: width } }, { children: [_jsx("h1", __assign({ className: 'font-bold text-xs mb-0.5 sm:mb-1.5' }, { children: title }), void 0), _jsx(Select, __assign({ disabled: isDisabled, suffixIcon: _jsx(CaretDownOutlined, { style: { color: 'black' } }, void 0), className: "border border-gray-400 py-" + paddingY + " w-full bg-white rounded-md focus:outline-none hover:border-primary-light focus:border-primary-light", showSearch: true, placeholder: placeholder, value: value, onChange: onChange, onSelect: onSelect, onClear: onClear, allowClear: allowClear, dropdownStyle: {
                        borderRadius: '8px',
                        borderWidth: '1px'
                    }, onSearch: function (value) {
                        searchBoxOnChange(value);
                    }, optionFilterProp: 'children' }, { children: data &&
                        data.length > 0 &&
                        data.map(function (item, index) { return (_jsx(Select.Option, __assign({ value: item.key, className: 'active:bg-primary' }, { children: item.value }), item.key + "_" + index)); }) }), void 0)] }), void 0));
    }
    if (isGroupData) {
        return (_jsxs("div", __assign({ style: { width: width } }, { children: [_jsx("h1", __assign({ className: 'font-bold text-xs mb-0.5 sm:mb-1.5' }, { children: title }), void 0), _jsx(Select, __assign({ disabled: isDisabled, suffixIcon: _jsx(CaretDownOutlined, { style: { color: 'black' } }, void 0), className: "border border-gray-400 py-" + paddingY + " w-full bg-white rounded-md focus:outline-none hover:border-primary-light focus:border-primary-light", placeholder: placeholder, value: value, onChange: onChange, onSelect: onSelect, showSearch: isSearchBoxVisible, onClear: onClear, dropdownStyle: {
                        borderRadius: '8px',
                        borderWidth: '1px',
                        width: dropdownWidth
                    }, onSearch: function (value) {
                        searchBoxOnChange(value);
                    }, dropdownMatchSelectWidth: dropdownMatchSelectWidth, allowClear: allowClear }, { children: options === null || options === void 0 ? void 0 : options.map(function (group) { return (_jsx(Select.OptGroup, __assign({ label: group.title }, { children: group.data.map(function (item, index) { return (_jsx(Select.Option, __assign({ value: item.key, className: 'active:bg-primary' }, { children: item.value }), (group === null || group === void 0 ? void 0 : group.key) + "_" + index + "_" + item.key)); }) }), void 0)); }) }), void 0)] }), void 0));
    }
    return (_jsxs("div", __assign({ style: { width: width } }, { children: [_jsx("h1", __assign({ className: 'font-bold text-xs mb-0.5 sm:mb-1.5' }, { children: title }), void 0), _jsx(Select, __assign({ disabled: isDisabled, suffixIcon: _jsx(CaretDownOutlined, { style: { color: 'black' } }, void 0), className: "border border-gray-400 py-" + paddingY + " w-full bg-white rounded-md focus:outline-none hover:border-primary-light focus:border-primary-light", placeholder: placeholder, value: value, onChange: onChange, onSelect: onSelect, showSearch: isSearchBoxVisible, onClear: onClear, dropdownStyle: {
                    borderRadius: '8px',
                    borderWidth: '1px',
                    width: dropdownWidth
                }, filterOption: false, onSearch: function (value) {
                    searchBoxOnChange(value);
                }, allowClear: allowClear, dropdownMatchSelectWidth: dropdownMatchSelectWidth, dropdownRender: function (menu) { return (_jsxs("div", { children: [menu, isPaginated && (_jsxs("div", __assign({ className: 'p-2', style: { textAlign: 'right' } }, { children: [_jsx(LeftOutlined, { onClick: function () { return loadProds('left'); }, className: "text-lg mr-1 sm:mr-2 " + ((maxPageIndex * 1 === 1 ||
                                        !currIndex ||
                                        currIndex * 1 <= 0 ||
                                        (Math.abs(currIndex * 1 - maxPageIndex * 1) > 1 &&
                                            currIndex * 1 > maxPageIndex * 1) ||
                                        currIndex * 1 === 1) &&
                                        'text-gray-300') }, void 0), _jsx(RightOutlined, { onClick: function () { return loadProds('right'); }, className: "text-lg ml-3 sm:ml-6 " + ((!currIndex || currIndex * 1 <= 0 || currIndex * 1 >= maxPageIndex) &&
                                        'text-gray-300') }, void 0)] }), void 0))] }, void 0)); } }, { children: data &&
                    data.length > 0 &&
                    data.map(function (item) { return (_jsx(Select.Option, __assign({ value: item.key, className: 'active:bg-primary' }, { children: item.value }), item.key)); }) }), void 0)] }), void 0));
}
Dropdown.defaultProps = {
    searchable: false,
    title: '',
    width: '100%',
    paddingY: '0.5',
    isDisabled: false,
    isPaginated: false,
    loadProds: function () { },
    isSearchBoxVisible: false,
    searchBoxOnChange: function () { }
};
export default Dropdown;
