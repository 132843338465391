import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable */
import React, { useState } from 'react';
import LoadingOverlay from 'react-loading-overlay-ts';
import { Spin } from 'antd';
var SortingScreen = function () {
    var _a = useState(false), isLoading = _a[0], setIsLoading = _a[1];
    return (_jsx(LoadingOverlay, { active: isLoading, spinner: _jsx(Spin, {}, void 0) }, void 0));
};
export default SortingScreen;
