import qz from 'qz-tray'
import $ from 'jquery'

export const startQZConnection = async (serverHost, serverPort) => {
  try {
    // Check if qz is available
    if (!qz || !qz.websocket || !qz.printers) {
      console.error('qz library is not properly initialized or available.')
      return
    }
    // Set certificate
    qz.security.setCertificatePromise(function (resolve, reject) {
      $.ajax(`${process.env.REACT_APP_API_BASE_URL}/qz-tray/digital-certificate.txt`).then(
        resolve,
        reject
      )
    })
    // Wait for qz to connect
    if (!qz.websocket.isActive()) {
      await qz.websocket
        .connect({
          host: [serverHost],
          port: {
            secure: [serverPort]
          },
          usingSecure: true,
          retries: 5,
          delay: 1
        })
        .then(() => {
          console.log('QZ Tray Connection established!!!', qz.websocket.getConnectionInfo())
        })
        .catch((error) => console.log(error))

      qz.security.setSignaturePromise(function (toSign) {
        return async function (resolve, reject) {
          fetch(`${process.env.REACT_APP_API_BASE_URL}/apps/qzSign?message=${toSign}`, {
            headers: { 'Content-Type': 'text/plain' }
          })
            .then(function (data) {
              data.ok ? resolve(data.text()) : reject(data.text())
            })
            .catch((err) => {
              console.error('Error in QZ Tray signing message:', err)
            })
        }
      })
    } else {
      console.log('QZ tray connection is already established', qz.websocket.getConnectionInfo())
    }
  } catch (error) {
    console.error('Error connecting to QZ Tray:', error)
  }
  console.log('startQZConnection() end')
}

export const startQZConnectionForPacking = async (setIsConnectionActive) => {
  try {
    // Check if qz is available
    if (!qz || !qz.websocket || !qz.printers) {
      console.error('qz library is not properly initialized or available.')
      return
    }
    // Set certificate
    qz.security.setCertificatePromise(function (resolve, reject) {
      $.ajax(`${process.env.REACT_APP_API_BASE_URL}/qz-tray/digital-certificate.txt`).then(
        resolve,
        reject
      )
    })
    // Wait for qz to connect
    if (!qz.websocket.isActive()) {
      await qz.websocket
        .connect({
          retries: 5,
          delay: 1
        })
        .then(() => {
          setIsConnectionActive(true)
          console.log('QZ Tray Connection established!!!', qz.websocket.getConnectionInfo())
        })
        .catch((error) => {
          setIsConnectionActive(false)
          console.log(error)
        })

      qz.security.setSignaturePromise(function (toSign) {
        return async function (resolve, reject) {
          fetch(`${process.env.REACT_APP_API_BASE_URL}/apps/qzSign?message=${toSign}`, {
            headers: { 'Content-Type': 'text/plain' }
          })
            .then(function (data) {
              data.ok ? resolve(data.text()) : reject(data.text())
            })
            .catch((err) => {
              console.error('Error in QZ Tray signing message:', err)
            })
        }
      })
    } else {
      setIsConnectionActive(true)
      console.log('QZ tray connection is already established', qz.websocket.getConnectionInfo())
    }
  } catch (error) {
    setIsConnectionActive(false)
    console.error('Error connecting to QZ Tray:', error)
  }
  console.log('startQZConnection() end')
}
