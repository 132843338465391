var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable */
import Button from '@fp-suite/core-ui/src/components/Button';
import DatePicker from '@fp-suite/core-ui/src/components/DatePicker';
import Dropdown from '@fp-suite/core-ui/src/components/Dropdown';
import Input from '@fp-suite/core-ui/src/components/Input';
import Table from '@fp-suite/core-ui/src/components/Table';
import { Form } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
var PackItems = function (_a) {
    var assetReservationList = _a.assetReservationList, item = _a.item, itemAssets = _a.itemAssets, onPackUpdate = _a.onPackUpdate, shipmentPackageSequenceId = _a.shipmentPackageSequenceId, activePackageSeqId = _a.activePackageSeqId, kitComponentProduct = _a.kitComponentProduct, shipmentData = _a.shipmentData, assetLotList = _a.assetLotList, activeTab = _a.activeTab, facilityLocationList = _a.facilityLocationList, shipmentLocationTypeList = _a.shipmentLocationTypeList;
    var form = Form.useForm()[0];
    var kitProductForm = Form.useForm()[0];
    var _b = useState([]), assetItems = _b[0], setAssetItems = _b[1];
    var _c = useState('New'), shipmentPackageSeqId = _c[0], setShipmentPackageSeqId = _c[1];
    var _d = useState([]), componentProducts = _d[0], setComponentProducts = _d[1];
    var _e = useState([]), itemAssetsList = _e[0], setItemAssetsList = _e[1];
    useEffect(function () {
        if ((assetReservationList === null || assetReservationList === void 0 ? void 0 : assetReservationList.length) > 0) {
            setAssetItems(assetReservationList === null || assetReservationList === void 0 ? void 0 : assetReservationList.map(function (asset) {
                if ((asset === null || asset === void 0 ? void 0 : asset.orderItemSeqId) === (item === null || item === void 0 ? void 0 : item.orderItemSeqId)) {
                    var lotDetails = assetLotList === null || assetLotList === void 0 ? void 0 : assetLotList.find(function (item) { return (item === null || item === void 0 ? void 0 : item.assetId) === (asset === null || asset === void 0 ? void 0 : asset.assetId); });
                    if (lotDetails) {
                        console.log((item === null || item === void 0 ? void 0 : item.productId) + "_lotFound", lotDetails);
                        // Merge asset with lotDetails if found
                        return __assign(__assign({}, asset), { lotDetails: __assign({}, lotDetails) });
                    }
                }
            }).filter(Boolean));
        }
        form.setFieldsValue({ qty: item === null || item === void 0 ? void 0 : item.quantityNotHandled });
    }, [item === null || item === void 0 ? void 0 : item.quantityNotHandled]);
    useEffect(function () {
        if ((itemAssets === null || itemAssets === void 0 ? void 0 : itemAssets.length) > 0) {
            var itemList = itemAssets === null || itemAssets === void 0 ? void 0 : itemAssets.filter(function (order) {
                var _a;
                var orderItemSeqId = order.orderItemSeqId;
                var itemOrders = (_a = item === null || item === void 0 ? void 0 : item.items) === null || _a === void 0 ? void 0 : _a.map(function (item) { return item === null || item === void 0 ? void 0 : item.orderItemSeqId; });
                return itemOrders === null || itemOrders === void 0 ? void 0 : itemOrders.includes(orderItemSeqId);
            }).map(function (filteredItem) {
                var _a, _b, _c;
                var lotInfo = assetLotList.find(function (item) { return item.lotId === filteredItem.lotId; });
                var resLotInfo = assetReservationList.find(function (item) { return item.assetReservationId === filteredItem.assetReservationId; });
                if (lotInfo) {
                    return __assign(__assign({}, filteredItem), { lotNumber: lotInfo.lotNumber, resQuantity: (_a = resLotInfo === null || resLotInfo === void 0 ? void 0 : resLotInfo.quantity) !== null && _a !== void 0 ? _a : null, quantityNotAvailable: (_b = resLotInfo === null || resLotInfo === void 0 ? void 0 : resLotInfo.quantityNotAvailable) !== null && _b !== void 0 ? _b : null, quantityNotIssued: (_c = resLotInfo === null || resLotInfo === void 0 ? void 0 : resLotInfo.quantityNotIssued) !== null && _c !== void 0 ? _c : null });
                }
                return filteredItem;
            });
            console.log('item list', itemList);
            setItemAssetsList(itemList);
        }
    }, [itemAssets]);
    useEffect(function () {
        if ((kitComponentProduct === null || kitComponentProduct === void 0 ? void 0 : kitComponentProduct.length) > 0) {
            var componentProducts_1 = kitComponentProduct === null || kitComponentProduct === void 0 ? void 0 : kitComponentProduct.filter(function (product) {
                return (item === null || item === void 0 ? void 0 : item.orderItemSeqId) === (product === null || product === void 0 ? void 0 : product.orderItemSeqId) &&
                    (product === null || product === void 0 ? void 0 : product.quantity) !== product.quantityTotal;
            });
            var newFieldValues_1 = {};
            componentProducts_1 === null || componentProducts_1 === void 0 ? void 0 : componentProducts_1.forEach(function (item) {
                var _a;
                var quantity = (_a = item === null || item === void 0 ? void 0 : item.quantityNotIssued) !== null && _a !== void 0 ? _a : 0;
                // (item?.quantity ?? item?.quantityNotIssued ?? 0) - (item?.quantityTotal ?? 0)
                newFieldValues_1["qty_" + (item === null || item === void 0 ? void 0 : item.componentProductId)] = quantity;
            });
            kitProductForm === null || kitProductForm === void 0 ? void 0 : kitProductForm.setFieldsValue(newFieldValues_1);
            setComponentProducts(componentProducts_1);
        }
    }, [kitComponentProduct]);
    var itemListColumns = [
        {
            title: (_jsx("div", __assign({ className: 'flex items-center relative h-5' }, { children: _jsx("p", __assign({ className: 'mr-1' }, { children: "Order Item" }), void 0) }), void 0)),
            render: function (orderItem, data) { return (_jsx("h1", __assign({ className: 'font-medium text-sm', onClick: function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
                    return [2 /*return*/];
                }); }); } }, { children: orderItem + ":" + (data === null || data === void 0 ? void 0 : data.orderItemSeqId) }), void 0)); },
            width: 200,
            dataIndex: 'orderId'
        },
        {
            title: (_jsx("div", __assign({ className: 'flex items-center relative h-5' }, { children: _jsx("p", __assign({ className: 'mr-1' }, { children: "Qty" }), void 0) }), void 0)),
            render: function (quantity) { return _jsx("h1", __assign({ className: 'font-medium text-sm' }, { children: quantity }), void 0); },
            width: 100,
            dataIndex: 'quantity'
        },
        {
            title: (_jsx("div", __assign({ className: 'flex items-center relative h-5' }, { children: _jsx("p", __assign({ className: 'mr-1' }, { children: "Rem" }), void 0) }), void 0)),
            render: function (quantityNotHandled) { return (_jsx("h1", __assign({ className: 'font-medium text-sm' }, { children: quantityNotHandled }), void 0)); },
            width: 100,
            dataIndex: 'quantityNotHandled'
        },
        {
            title: (_jsx("div", __assign({ className: 'flex items-center relative h-5' }, { children: _jsx("p", __assign({ className: 'mr-1' }, { children: "Asset Reservations" }), void 0) }), void 0)),
            render: function (quantity, data) {
                return (_jsx("h1", __assign({ className: 'font-medium text-sm' }, { children: (assetItems === null || assetItems === void 0 ? void 0 : assetItems.length) > 0 &&
                        (assetItems === null || assetItems === void 0 ? void 0 : assetItems.map(function (item, index) {
                            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
                            return (_jsxs(React.Fragment, { children: [_jsxs("p", { children: ["Res " + ((_a = item === null || item === void 0 ? void 0 : item.quantity) !== null && _a !== void 0 ? _a : 0) + ", Not Avail: " + ((_b = item === null || item === void 0 ? void 0 : item.quantityNotHandled) !== null && _b !== void 0 ? _b : 0) + ", Not Issued " + ((_c = item === null || item === void 0 ? void 0 : item.quantityNotIssued) !== null && _c !== void 0 ? _c : 0) + " ", _jsxs("span", __assign({ className: 'text-gray-500' }, { children: ["Asset ", item === null || item === void 0 ? void 0 : item.assetId] }), void 0)] }, void 0), ((_d = item === null || item === void 0 ? void 0 : item.lotDetails) === null || _d === void 0 ? void 0 : _d.lotId) && (_jsxs("p", { children: [(_e = item === null || item === void 0 ? void 0 : item.lotDetails) === null || _e === void 0 ? void 0 : _e.lotId, ":", (_f = item === null || item === void 0 ? void 0 : item.lotDetails) === null || _f === void 0 ? void 0 : _f.organizationPartyId, ' ', (_g = item === null || item === void 0 ? void 0 : item.lotDetails) === null || _g === void 0 ? void 0 : _g.lotNumber, " Mgf", ' ', (_j = moment((_h = item === null || item === void 0 ? void 0 : item.lotDetails) === null || _h === void 0 ? void 0 : _h.manufacturedDate)) === null || _j === void 0 ? void 0 : _j.format('YYYY-MM-DD'), " Exp", ' ', (_l = moment((_k = item === null || item === void 0 ? void 0 : item.lotDetails) === null || _k === void 0 ? void 0 : _k.expectedEndOfLife)) === null || _l === void 0 ? void 0 : _l.format('YYYY-MM-DD')] }, void 0))] }, index));
                        })) }), void 0));
            },
            width: 400,
            dataIndex: 'quantity'
        },
        {
            title: (_jsx("div", __assign({ className: 'flex items-center relative h-5' }, { children: _jsx("p", __assign({ className: 'mr-1' }, { children: "Serial No." }), void 0) }), void 0)),
            render: function (productId) {
                return (_jsx("h1", __assign({ className: 'font-medium text-sm' }, { children: assetReservationList.map(function (reservationItem) {
                        var _a;
                        return (_a = assetLotList === null || assetLotList === void 0 ? void 0 : assetLotList.find(function (item) {
                            return (item === null || item === void 0 ? void 0 : item.assetId) === (reservationItem === null || reservationItem === void 0 ? void 0 : reservationItem.assetId) && productId === (item === null || item === void 0 ? void 0 : item.productId);
                        })) === null || _a === void 0 ? void 0 : _a.serialNumber;
                    }) }), void 0));
            },
            width: 400,
            dataIndex: 'productId'
        },
        {
            title: (_jsx("div", __assign({ className: 'flex items-center relative h-5' }, { children: _jsx("p", __assign({ className: 'mr-1' }, { children: "Package" }), void 0) }), void 0)),
            render: function (packageId) {
                if ((item === null || item === void 0 ? void 0 : item.quantityNotHandled) <= 0) {
                    return;
                }
                return (_jsx(Dropdown, { data: shipmentPackageSequenceId, 
                    // title='Package'
                    isDisabled: false, placeholder: 'Select', value: shipmentPackageSeqId, onChange: function (val) {
                        setShipmentPackageSeqId(val);
                    } }, void 0));
            },
            width: 100,
            dataIndex: 'package'
        },
        {
            title: (_jsx("div", __assign({ className: 'flex items-center relative h-5' }, { children: _jsx("p", __assign({ className: 'mr-1' }, { children: "Pack Qty" }), void 0) }), void 0)),
            render: function (packQty) {
                if ((item === null || item === void 0 ? void 0 : item.quantityNotHandled) <= 0) {
                    return;
                }
                return (_jsx(Form, __assign({ form: form }, { children: _jsx(Form.Item, __assign({ name: 'qty', className: 'mt-5' }, { children: _jsx(Input, { placeholder: 'QTY', title: '' }, void 0) }), void 0) }), void 0));
            },
            width: 100,
            dataIndex: 'packQty'
        },
        {
            title: (_jsx("div", __assign({ className: 'flex items-center relative h-5' }, { children: _jsx("p", __assign({ className: 'mr-1' }, { children: " " }), void 0) }), void 0)),
            render: function (packQty) {
                if ((item === null || item === void 0 ? void 0 : item.quantityNotHandled) <= 0) {
                    return;
                }
                return (_jsx(Button, { buttonText: 'Pack', onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            onPackUpdate(item === null || item === void 0 ? void 0 : item.productId, form.getFieldValue('qty'), shipmentPackageSeqId);
                            return [2 /*return*/];
                        });
                    }); } }, void 0));
            },
            width: 150,
            dataIndex: 'packQty'
        }
    ];
    var issuedItemListColumns = [
        {
            title: (_jsx("div", __assign({ className: 'flex items-center relative h-5' }, { children: _jsx("p", __assign({ className: 'mr-1' }, { children: "Issued" }), void 0) }), void 0)),
            render: function (quantity, data) { return (_jsx("h1", __assign({ className: 'font-medium text-sm' }, { children: data === null || data === void 0 ? void 0 : data.quantity }), void 0)); },
            width: 100,
            dataIndex: 'quantity'
        },
        {
            title: (_jsx("div", __assign({ className: 'flex items-center relative h-5' }, { children: _jsx("p", __assign({ className: 'mr-1' }, { children: "Order Item" }), void 0) }), void 0)),
            render: function (orderId, data) { return (_jsx(_Fragment, { children: _jsxs("h1", __assign({ className: 'font-medium text-sm' }, { children: [" ", orderId + ":" + (data === null || data === void 0 ? void 0 : data.orderItemSeqId)] }), void 0) }, void 0)); },
            width: 100,
            dataIndex: 'orderId'
        },
        {
            title: (_jsx("div", __assign({ className: 'flex items-center relative h-5' }, { children: _jsx("p", __assign({ className: 'mr-1' }, { children: "Asset" }), void 0) }), void 0)),
            render: function (orderId, data) { return (_jsx(_Fragment, { children: _jsxs("h1", __assign({ className: 'font-medium text-sm' }, { children: [" ", "" + (data === null || data === void 0 ? void 0 : data.assetId)] }), void 0) }, void 0)); },
            width: 100,
            dataIndex: 'orderId'
        },
        {
            title: (_jsx("div", __assign({ className: 'flex items-center relative h-5' }, { children: _jsx("p", __assign({ className: 'mr-1' }, { children: "Reservation Lot" }), void 0) }), void 0)),
            render: function (assetReservationId, data) {
                var _a, _b;
                return (_jsxs(_Fragment, { children: [_jsx("h1", __assign({ className: 'font-medium text-sm' }, { children: (data === null || data === void 0 ? void 0 : data.resQuantity)
                                ? assetReservationId + " - Res:" + (data === null || data === void 0 ? void 0 : data.resQuantity) + ",Not Avail:" + (data === null || data === void 0 ? void 0 : data.quantityNotAvailable) + ",Not Issued:" + (data === null || data === void 0 ? void 0 : data.quantityNotIssued)
                                : assetReservationId }), void 0), (data === null || data === void 0 ? void 0 : data.lotId) && (_jsx("h1", __assign({ className: 'font-medium text-sm' }, { children: (data === null || data === void 0 ? void 0 : data.lotId) + ":" + ((_a = data === null || data === void 0 ? void 0 : data.ownerPartyId) !== null && _a !== void 0 ? _a : '') + " " + ((_b = data === null || data === void 0 ? void 0 : data.lotNumber) !== null && _b !== void 0 ? _b : '') + ((data === null || data === void 0 ? void 0 : data.manufacturedDate)
                                ? "Mgf " + moment(data === null || data === void 0 ? void 0 : data.manufacturedDate).format('YYYY-MM-DD')
                                : '') + " " + ((data === null || data === void 0 ? void 0 : data.expectedEndOfLife)
                                ? "Exp " + moment(data === null || data === void 0 ? void 0 : data.expectedEndOfLife).format('YYYY-MM-DD')
                                : '') }), void 0))] }, void 0));
            },
            width: 200,
            dataIndex: 'assetReservationId'
        },
        {
            title: (_jsx("div", __assign({ className: 'flex items-center relative h-5' }, { children: _jsx("p", __assign({ className: 'mr-1' }, { children: "Serial No." }), void 0) }), void 0)),
            render: function (serialNumber) { return (_jsx(_Fragment, { children: _jsx("h1", __assign({ className: 'font-medium text-sm' }, { children: serialNumber !== null && serialNumber !== void 0 ? serialNumber : '' }), void 0) }, void 0)); },
            width: 200,
            dataIndex: 'serialNumber'
        },
        {
            title: (_jsx("div", __assign({ className: 'flex items-center relative h-5' }, { children: _jsx("p", __assign({ className: 'mr-1' }, { children: "Was Stored At" }), void 0) }), void 0)),
            render: function (locationSeqId, data) {
                var _a, _b, _c, _d, _e, _f, _g;
                var facilityLocation = facilityLocationList === null || facilityLocationList === void 0 ? void 0 : facilityLocationList.find(function (item) { return (item === null || item === void 0 ? void 0 : item.locationSeqId) === locationSeqId; });
                var locationValue = shipmentLocationTypeList === null || shipmentLocationTypeList === void 0 ? void 0 : shipmentLocationTypeList.find(function (enumId) { return (enumId === null || enumId === void 0 ? void 0 : enumId.enumId) === (facilityLocation === null || facilityLocation === void 0 ? void 0 : facilityLocation.locationTypeEnumId); });
                return (_jsx("h1", __assign({ className: 'font-medium text-sm' }, { children: ((_a = facilityLocation === null || facilityLocation === void 0 ? void 0 : facilityLocation.locationSeqId) !== null && _a !== void 0 ? _a : '') + " " + ((_b = facilityLocation === null || facilityLocation === void 0 ? void 0 : facilityLocation.description) !== null && _b !== void 0 ? _b : '') + " [" + ((_c = facilityLocation === null || facilityLocation === void 0 ? void 0 : facilityLocation.areaId) !== null && _c !== void 0 ? _c : '') + ":" + ((_d = facilityLocation === null || facilityLocation === void 0 ? void 0 : facilityLocation.aisleId) !== null && _d !== void 0 ? _d : '') + ":" + ((_e = facilityLocation === null || facilityLocation === void 0 ? void 0 : facilityLocation.sectionId) !== null && _e !== void 0 ? _e : '') + ":" + ((_f = facilityLocation === null || facilityLocation === void 0 ? void 0 : facilityLocation.levelId) !== null && _f !== void 0 ? _f : '') + "] " + ((_g = locationValue === null || locationValue === void 0 ? void 0 : locationValue.description) !== null && _g !== void 0 ? _g : '') }), void 0));
            },
            width: 200,
            dataIndex: 'locationSeqId'
        }
        // {
        //   title: (
        //     <div className='flex items-center relative h-5'>
        //       <p className='mr-1'> </p>
        //     </div>
        //   ),
        //   render: (quantity: any) => {
        //     if (quantity === 0) {
        //       return
        //     }
        //     return (
        //       <Button
        //         buttonText='Unpack'
        //         onClick={async () => {
        //           onPackUpdate(item?.productId, form.getFieldValue('qty'), shipmentPackageSeqId)
        //         }}
        //       />
        //     )
        //   },
        //   width: 150,
        //   dataIndex: 'quantity'
        // }
    ];
    var kitProductItemColumns = __spreadArray([
        {
            title: (_jsx("div", __assign({ className: 'flex items-center relative h-5' }, { children: _jsx("p", __assign({ className: 'mr-1' }, { children: "Component" }), void 0) }), void 0)),
            render: function (componentProductId, data) {
                var _a, _b;
                return (_jsx("h1", __assign({ className: 'font-medium text-sm', onClick: function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
                        return [2 /*return*/];
                    }); }); } }, { children: ((_b = (_a = data === null || data === void 0 ? void 0 : data.sku) !== null && _a !== void 0 ? _a : data === null || data === void 0 ? void 0 : data.upc) !== null && _b !== void 0 ? _b : componentProductId) + ": " + (data === null || data === void 0 ? void 0 : data.productName) }), void 0));
            },
            width: 200,
            dataIndex: 'componentProductId'
        },
        {
            title: (_jsx("div", __assign({ className: 'flex items-center relative h-5' }, { children: _jsx("p", { className: 'mr-1' }, void 0) }), void 0)),
            render: function () { return ''; },
            width: 100,
            dataIndex: 'componentProductId'
        }
    ], (activeTab
        ? [
            {
                title: (_jsx("div", __assign({ className: 'flex items-center relative h-5' }, { children: _jsx("p", __assign({ className: 'mr-1' }, { children: "Total Qty" }), void 0) }), void 0)),
                render: function (totalQty) { return (_jsx("div", __assign({ style: { maxWidth: 150 } }, { children: _jsx("h1", { children: totalQty }, void 0) }), void 0)); },
                width: 50,
                dataIndex: 'totalQty'
            },
            {
                title: (_jsx("div", __assign({ className: 'flex items-center relative h-5' }, { children: _jsx("p", __assign({ className: 'mr-1' }, { children: "Rem Qty" }), void 0) }), void 0)),
                render: function (quantityNotIssued, data) { return (_jsx("div", __assign({ style: { maxWidth: 100 } }, { children: _jsx("h1", { children: quantityNotIssued }, void 0) }), void 0)); },
                width: 50,
                dataIndex: 'quantityNotIssued'
            },
            {
                title: (_jsx("div", __assign({ className: 'flex items-center relative h-5' }, { children: _jsx("p", __assign({ className: 'mr-1' }, { children: "Pack Qty" }), void 0) }), void 0)),
                render: function (quantityTotal, data) { return (_jsx("h1", { children: Number.isNaN((data === null || data === void 0 ? void 0 : data.totalQty) - (data === null || data === void 0 ? void 0 : data.quantityNotIssued))
                        ? 0
                        : (data === null || data === void 0 ? void 0 : data.totalQty) - (data === null || data === void 0 ? void 0 : data.quantityNotIssued) }, void 0)); },
                width: 50,
                dataIndex: 'quantityTotal'
            }
        ]
        : [
            {
                title: (_jsx("div", __assign({ className: 'flex items-center relative h-5' }, { children: _jsx("p", __assign({ className: 'mr-1' }, { children: "Pack Date" }), void 0) }), void 0)),
                render: function (quantity) { return (_jsx("div", __assign({ style: { maxWidth: 150 } }, { children: _jsx(DatePicker, { title: '', isTimeShown: false, value: (shipmentData === null || shipmentData === void 0 ? void 0 : shipmentData.estimatedShipDate)
                            ? moment(shipmentData === null || shipmentData === void 0 ? void 0 : shipmentData.estimatedShipDate)
                            : moment() }, void 0) }), void 0)); },
                width: 50,
                dataIndex: 'quantity'
            },
            {
                title: (_jsx("div", __assign({ className: 'flex items-center relative h-5' }, { children: _jsx("p", __assign({ className: 'mr-1' }, { children: "Package" }), void 0) }), void 0)),
                render: function (packageId) { return (_jsx("div", __assign({ style: { maxWidth: 100 } }, { children: _jsx(Dropdown, { data: shipmentPackageSequenceId, isDisabled: false, placeholder: 'Select', value: activePackageSeqId }, void 0) }), void 0)); },
                width: 50,
                dataIndex: 'package'
            },
            {
                title: (_jsx("div", __assign({ className: 'flex items-center relative h-5' }, { children: _jsx("p", __assign({ className: 'mr-1' }, { children: "Pack Qty" }), void 0) }), void 0)),
                render: function (quantityTotal, data) { return (_jsx(Form, __assign({ form: kitProductForm, style: { maxWidth: 80 } }, { children: _jsx(Form.Item, __assign({ name: "qty_" + (data === null || data === void 0 ? void 0 : data.componentProductId), className: 'relative h-5' }, { children: _jsx(Input, { placeholder: 'QTY', title: '' }, void 0) }), void 0) }), void 0)); },
                width: 50,
                dataIndex: 'quantityTotal'
            }
        ]), true);
    return (_jsxs(_Fragment, { children: [!activeTab && (_jsx(Table, { columns: itemListColumns, dataSource: item === null || item === void 0 ? void 0 : item.items, isPaginated: false }, 'packItems')), (componentProducts === null || componentProducts === void 0 ? void 0 : componentProducts.length) > 0 && (_jsx(Table, { columns: kitProductItemColumns, dataSource: componentProducts, isPaginated: false }, 'kitItems')), (itemAssetsList === null || itemAssetsList === void 0 ? void 0 : itemAssetsList.length) > 0 && !activeTab && (_jsx(Table, { columns: issuedItemListColumns, dataSource: itemAssetsList, isPaginated: false }, 'itemAsset'))] }, void 0));
};
export default PackItems;
