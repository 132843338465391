var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { Form } from 'antd';
import Input from '@fp-suite/core-ui/src/components/Input';
import { BsKeyboard, BsKeyboardFill } from 'react-icons/bs';
var ScanLocation = function (_a) {
    var scanCart = _a.scanCart, onLocationScan = _a.onLocationScan, setCurrentScreen = _a.setCurrentScreen, errorMsgText = _a.errorMsgText, scannedLocationIncorrect = _a.scannedLocationIncorrect, setScannedLocationIncorrect = _a.setScannedLocationIncorrect;
    var form = Form.useForm()[0];
    var _b = useState('none'), inputMode = _b[0], setInputMode = _b[1];
    var onStartScanLocationScan = function (event) {
        if (event.key === 'Enter') {
            form.setFieldsValue({
                scannedLocation: ''
            });
            onLocationScan(event.target.value);
        }
        else {
            setScannedLocationIncorrect(false);
        }
    };
    return (_jsx("div", { children: _jsxs("div", __assign({ className: 'bg-white p-5 pb-0.5 rounded-lg shadow-sm' }, { children: [_jsxs("div", __assign({ className: 'flex items-center' }, { children: [_jsx(Form, { children: _jsx(Form.Item, __assign({ validateStatus: scannedLocationIncorrect ? 'error' : '', help: scannedLocationIncorrect && errorMsgText, className: 'w-full', name: 'scannedLocation' }, { children: _jsx(Input, { placeholder: '', title: 'Scan Start Location', onKeyDown: onStartScanLocationScan, autoFocus: true, allowClear: true, fromHandheld: true, inputMode: inputMode }, void 0) }), void 0) }, void 0), !inputMode ? (_jsx(BsKeyboardFill, { size: 32, className: 'mt-5 ml-2 cursor-pointer', color: '#093479', onClick: function () { return setInputMode('none'); } }, void 0)) : (_jsx(BsKeyboard, { size: 32, className: 'mt-5 ml-2 cursor-pointer', onClick: function () { return setInputMode(''); } }, void 0))] }), void 0), _jsx("h1", __assign({ className: 'mb-2 text-sm text-gray-400 font-semibold' }, { children: "Scan the nearest location" }), void 0)] }), void 0) }, void 0));
};
export default ScanLocation;
