var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { LeftOutlined } from '@ant-design/icons';
function BackBtn(_a) {
    var onClick = _a.onClick, _b = _a.type, type = _b === void 0 ? 'submit' : _b;
    return (_jsx("button", __assign({ onClick: onClick, type: type, className: 'bg-transparent rounded-md cursor-pointer', style: { borderColor: '#EB61A8' } }, { children: _jsx("div", __assign({ className: 'flex flex-row justify-center items-center ' }, { children: _jsx("div", __assign({ className: 'pb-1' }, { children: _jsx(LeftOutlined, { style: { color: '#000', fontSize: '22px' } }, void 0) }), void 0) }), void 0) }), void 0));
}
export default BackBtn;
