var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { Form, notification } from 'antd';
import Input from '@fp-suite/core-ui/src/components/Input';
import Table from '@fp-suite/core-ui/src/components/Table';
import Button from '@fp-suite/core-ui/src/components/Button';
import { getListOfPickRuns, searchPickRunId, getPickRunDetails, userSelector } from '../redux/authSlice';
import MainHeading from './MainHeading';
import LookUp from './LookUp';
import { lookupTableColumnsForPickRunItems } from './MainHeading';
var PickShipment = function (_a) {
    var _b, _c, _d;
    var setIsLoading = _a.setIsLoading;
    var organizationsList = useSelector(userSelector).organizationsList;
    var pickRunForm = Form.useForm()[0];
    var dispatch = useDispatch();
    var history = useHistory();
    var _e = useState(true), showOpenOrders = _e[0], setShowOpenOrders = _e[1];
    var _f = useState('bulkpick'), currentActiveSubscreen = _f[0], setCurrentActiveSubscreen = _f[1];
    var _g = useState([
        {
            pickRunId: 123456,
            ownerPartyId: 787873,
            pickRunTypeId: 'PrtSingle',
            statusId: 'PrReady',
            cartId: 7654,
            startDate: '',
            endDate: ''
        }
    ]), openOrdersData = _g[0], setOpenOrdersData = _g[1];
    var _h = useState([]), completedOrdersData = _h[0], setCompletedOrdersData = _h[1];
    var _j = useState(false), isLookUpVisible = _j[0], setLookUpVisible = _j[1];
    var _k = useState(''), pickRunErrorMsgText = _k[0], setPickRunErrorMsgText = _k[1];
    var _l = useState(false), scannedPickRunIdIncorrect = _l[0], setScannedPickRunIdIncorrect = _l[1];
    var _m = useState({
        pickRunId: 123456,
        ownerPartyId: 787873,
        pickRunTypeId: 'PrtSingle',
        statusId: 'PrReady',
        cartId: 7654,
        startDate: '',
        endDate: ''
    }), pickRunDetails = _m[0], setPickRunDetails = _m[1];
    var _o = useState(true), setContinueBtnDisabled = _o[1];
    var _p = useState('1'), openCurrIndex = _p[0], setOpenCurrIndex = _p[1];
    var _q = useState('1'), completedCurrIndex = _q[0], setCompletedCurrIndex = _q[1];
    var _r = useState(''), openMaxPageIndex = _r[0], setOpenMaxPageIndex = _r[1];
    var _s = useState(''), completedMaxPageIndex = _s[0], setCompletedMaxPageIndex = _s[1];
    var _t = useState(''), openRecordCount = _t[0], setOpenRecordCount = _t[1];
    var _u = useState(''), completedRecordCount = _u[0], setCompletedRecordCount = _u[1];
    var _v = useState(''), pickRunId = _v[0], setPickRunId = _v[1];
    var isMobileDevice = useMediaQuery({
        query: '(max-device-width: 640px)'
    });
    var isMediumDevice = useMediaQuery({
        query: '(max-device-width: 768px)'
    });
    var isLargeDevice = useMediaQuery({
        query: '(min-device-width: 1024px)'
    });
    var nextSetOfOpenPickRuns = function (pageIndex) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [2 /*return*/];
                case 1:
                    _a.sent();
                    setIsLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var nextSetOfCompletedPickRuns = function (pageIndex) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [2 /*return*/];
                case 1:
                    _a.sent();
                    setIsLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var loadOpenNextPickRuns = function (index) {
        if (!openCurrIndex || openCurrIndex * 1 === 0) {
            return;
        }
        if (openCurrIndex * 1 > openMaxPageIndex) {
            return;
        }
        var passedIndex = index * 1 - 1;
        setOpenCurrIndex(index);
        nextSetOfOpenPickRuns(passedIndex);
    };
    var loadCompletedNextPickRuns = function (index) {
        if (!completedCurrIndex || completedCurrIndex * 1 === 0) {
            return;
        }
        if (completedCurrIndex * 1 > completedMaxPageIndex) {
            return;
        }
        var passedIndex = index * 1 - 1;
        setCompletedCurrIndex(index);
        nextSetOfCompletedPickRuns(passedIndex);
    };
    var onPickRunIdSubmit = function (event) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(event.key === 'Enter')) return [3 /*break*/, 2];
                    setLookUpVisible(true);
                    return [2 /*return*/];
                case 1:
                    _a.sent();
                    setIsLoading(false);
                    return [3 /*break*/, 3];
                case 2:
                    setScannedPickRunIdIncorrect(false);
                    setPickRunId(event.target.value);
                    _a.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var openOrdersColumns = [
        {
            title: (_jsx("div", __assign({ className: 'flex items-center relative h-5' }, { children: _jsx("p", __assign({ className: 'mr-1' }, { children: "Picking ID" }), void 0) }), void 0)),
            render: function (pickRunId) { return (_jsx("h1", __assign({ className: 'cursor-pointer underline text-secondary-text font-medium text-sm', onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                setLookUpVisible(true);
                                return [2 /*return*/];
                            case 1:
                                _a.sent();
                                setIsLoading(false);
                                return [2 /*return*/];
                        }
                    });
                }); } }, { children: pickRunId }), void 0)); },
            dataIndex: 'pickRunId'
        },
        {
            title: (_jsx("div", __assign({ className: 'flex items-center relative h-5' }, { children: _jsx("p", __assign({ className: 'mr-1' }, { children: "Organization" }), void 0) }), void 0)),
            render: function (ownerPartyId) {
                var matchedOrgId = ownerPartyId;
                organizationsList.forEach(function (item) {
                    if (item.key === ownerPartyId) {
                        if (item.value) {
                            matchedOrgId = item.value;
                        }
                    }
                });
                return _jsx("h1", __assign({ className: 'font-medium text-sm' }, { children: matchedOrgId }), void 0);
            },
            dataIndex: 'ownerPartyId'
        },
        {
            title: (_jsx("div", __assign({ className: 'flex items-center relative h-5' }, { children: _jsx("p", __assign({ className: 'mr-1' }, { children: "Type" }), void 0) }), void 0)),
            render: function (pickRunTypeId) { return (_jsx("h1", __assign({ className: 'font-medium text-sm' }, { children: pickRunTypeId === 'PrtSingle'
                    ? 'Single'
                    : pickRunTypeId === 'PrtMulti'
                        ? 'Multi'
                        : pickRunTypeId === 'PrtOrderLevel'
                            ? 'Order Level'
                            : pickRunTypeId === 'PrtBulkSingle'
                                ? 'Bulk Single'
                                : '' }), void 0)); },
            dataIndex: 'pickRunTypeId'
        },
        {
            title: (_jsx("div", __assign({ className: 'flex items-center relative h-5' }, { children: _jsx("p", __assign({ className: 'mr-1' }, { children: "Status" }), void 0) }), void 0)),
            render: function (statusId) { return (_jsx("h1", __assign({ className: 'font-medium text-sm' }, { children: statusId === 'PrInProgress' ? 'In Progress' : statusId === 'PrReady' ? 'Ready' : '' }), void 0)); },
            dataIndex: 'statusId'
        },
        {
            title: (_jsx("div", __assign({ className: 'flex items-center relative h-5' }, { children: _jsx("p", __assign({ className: 'mr-1' }, { children: "Cart ID" }), void 0) }), void 0)),
            render: function (cartId) { return _jsx("h1", __assign({ className: 'font-medium text-sm' }, { children: cartId }), void 0); },
            dataIndex: 'cartId'
        },
        // {
        //   title: (
        //     <div className='flex items-center relative h-5'>
        //       <p className='mr-1'>Dropzone ID</p>
        //     </div>
        //   ),
        //   dataIndex: 'dropzoneId'
        // },
        {
            title: (_jsx("div", __assign({ className: 'flex items-center relative h-5' }, { children: _jsx("p", __assign({ className: 'mr-1' }, { children: "Start Date" }), void 0) }), void 0)),
            render: function (startDate) { return (_jsx("h1", __assign({ className: 'font-medium text-sm' }, { children: startDate ? new Date(startDate).toISOString().slice(0, 10) : '' }), void 0)); },
            dataIndex: 'startDate'
        },
        {
            title: (_jsx("div", __assign({ className: 'flex items-center relative h-5' }, { children: _jsx("p", __assign({ className: 'mr-1' }, { children: "End Date" }), void 0) }), void 0)),
            render: function (endDate) { return (_jsx("h1", __assign({ className: 'font-medium text-sm' }, { children: endDate ? new Date(endDate).toISOString().slice(0, 10) : '' }), void 0)); },
            dataIndex: 'endDate'
        }
    ];
    var completedOrdersColumns = [
        {
            title: (_jsx("div", __assign({ className: 'flex items-center relative h-5' }, { children: _jsx("p", __assign({ className: 'mr-1' }, { children: "Picking ID" }), void 0) }), void 0)),
            render: function (pickRunId) { return (_jsx("h1", __assign({ className: 'cursor-pointer underline text-secondary-text font-medium text-sm', onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                setIsLoading(true);
                                return [4 /*yield*/, dispatch(searchPickRunId({
                                        pickRunId: pickRunId,
                                        setPickRunErrorMsgText: setPickRunErrorMsgText,
                                        setScannedPickRunIdIncorrect: setScannedPickRunIdIncorrect,
                                        setPickRunDetails: setPickRunDetails,
                                        setContinueBtnDisabled: setContinueBtnDisabled,
                                        setLookUpVisible: setLookUpVisible
                                    }))];
                            case 1:
                                _a.sent();
                                setIsLoading(false);
                                return [2 /*return*/];
                        }
                    });
                }); } }, { children: pickRunId }), void 0)); },
            dataIndex: 'pickRunId'
        },
        {
            title: (_jsx("div", __assign({ className: 'flex items-center relative h-5' }, { children: _jsx("p", __assign({ className: 'mr-1' }, { children: "Organization" }), void 0) }), void 0)),
            render: function (ownerPartyId) {
                var matchedOrgId = ownerPartyId;
                organizationsList.forEach(function (item) {
                    if (item.key === ownerPartyId) {
                        if (item.value) {
                            matchedOrgId = item.value;
                        }
                    }
                });
                return _jsx("h1", __assign({ className: 'font-medium text-sm' }, { children: matchedOrgId }), void 0);
            },
            dataIndex: 'ownerPartyId'
        },
        {
            title: (_jsx("div", __assign({ className: 'flex items-center relative h-5' }, { children: _jsx("p", __assign({ className: 'mr-1' }, { children: "Type" }), void 0) }), void 0)),
            render: function (pickRunTypeId) { return (_jsx("h1", __assign({ className: 'font-medium text-sm' }, { children: pickRunTypeId === 'PrtSingle'
                    ? 'Single'
                    : pickRunTypeId === 'PrtMulti'
                        ? 'Multi'
                        : pickRunTypeId === 'PrtOrderLevel'
                            ? 'Order Level'
                            : pickRunTypeId === 'PrtBulkSingle'
                                ? 'Bulk Single'
                                : '' }), void 0)); },
            dataIndex: 'pickRunTypeId'
        },
        {
            title: (_jsx("div", __assign({ className: 'flex items-center relative h-5' }, { children: _jsx("p", __assign({ className: 'mr-1' }, { children: "Cart ID" }), void 0) }), void 0)),
            render: function (cartId) { return _jsx("h1", __assign({ className: 'font-medium text-sm' }, { children: cartId }), void 0); },
            dataIndex: 'cartId'
        },
        {
            title: (_jsx("div", __assign({ className: 'flex items-center relative h-5' }, { children: _jsx("p", __assign({ className: 'mr-1' }, { children: "Drop Off Location" }), void 0) }), void 0)),
            render: function (dropOffLocationSeqId) {
                var dropoffLocation = '';
                if (dropOffLocationSeqId && dropOffLocationSeqId.length > 0) {
                    dropOffLocationSeqId.forEach(function (item) {
                        if (dropoffLocation) {
                            dropoffLocation += ", " + item;
                        }
                        else {
                            dropoffLocation = item;
                        }
                    });
                }
                return _jsx("h1", __assign({ className: 'font-medium text-sm' }, { children: dropoffLocation }), void 0);
            },
            dataIndex: 'dropOffLocationSeqId'
        },
        {
            title: (_jsx("div", __assign({ className: 'flex items-center relative h-5' }, { children: _jsx("p", __assign({ className: 'mr-1' }, { children: "Status" }), void 0) }), void 0)),
            render: function (statusId) { return (_jsx("h1", __assign({ className: 'font-medium text-sm' }, { children: statusId === 'PrCompleted' ? 'Completed' : '' }), void 0)); },
            dataIndex: 'statusId'
        },
        {
            title: (_jsx("div", __assign({ className: 'flex items-center relative h-5' }, { children: _jsx("p", __assign({ className: 'mr-1' }, { children: "Start Date" }), void 0) }), void 0)),
            render: function (startDate) { return (_jsx("h1", __assign({ className: 'font-medium text-sm' }, { children: startDate ? new Date(startDate).toISOString().slice(0, 10) : '' }), void 0)); },
            dataIndex: 'startDate'
        },
        {
            title: (_jsx("div", __assign({ className: 'flex items-center relative h-5' }, { children: _jsx("p", __assign({ className: 'mr-1' }, { children: "End Date" }), void 0) }), void 0)),
            render: function (endDate) { return (_jsx("h1", __assign({ className: 'font-medium text-sm' }, { children: endDate ? new Date(endDate).toISOString().slice(0, 10) : '' }), void 0)); },
            dataIndex: 'endDate'
        }
    ];
    useEffect(function () {
        var fetchData = function () { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setIsLoading(true);
                        return [4 /*yield*/, dispatch(getListOfPickRuns({
                                setPickRunsList: setOpenOrdersData,
                                statusId: 'PrInProgress,PrReady',
                                setMaxPageIndex: setOpenMaxPageIndex,
                                setRecordCount: setOpenRecordCount,
                                pageIndex: 0
                            }))];
                    case 1:
                        _a.sent();
                        setIsLoading(false);
                        return [2 /*return*/];
                }
            });
        }); };
        // fetchData()
    }, []); // eslint-disable-line
    return (_jsxs("div", __assign({ className: 'px-4 sm:px-8 lg:px-20 pt-1 lg:pt-3 pb-16 h-screen w-full mx-auto' }, { children: [_jsx(LookUp, __assign({ width: isMobileDevice ? '90%' : isMediumDevice ? '70%' : isLargeDevice ? '55%' : '70%', title: 'Picking Details', visible: isLookUpVisible, onCancel: function () { return [setLookUpVisible(false)]; } }, { children: _jsx("div", __assign({ className: 'mb-72' }, { children: (pickRunDetails === null || pickRunDetails === void 0 ? void 0 : pickRunDetails.pickRunId) && (_jsxs("div", __assign({ className: 'mb-4' }, { children: [_jsx("div", __assign({ className: 'mb-4' }, { children: _jsx("div", __assign({ className: 'w-full flex flex-row justify-between' }, { children: _jsxs("div", __assign({ className: 'w-1/2' }, { children: [pickRunDetails.pickRunId && (_jsxs("div", __assign({ className: 'flex flex-row items-center my-1' }, { children: [_jsxs("h1", __assign({ className: 'font-semibold text-black text-base mr-5 w-3/5' }, { children: ["Picking ID #", pickRunDetails.pickRunId] }), void 0), pickRunDetails.statusId && (_jsx("div", __assign({ className: 'w-3/5' }, { children: _jsx("h1", __assign({ style: {
                                                                backgroundColor: '#EFF2F5',
                                                                outlineColor: '#707070',
                                                                border: '1px solid #707070'
                                                            }, className: 'px-1.5 py-0.5 text-center tracking-wider border font-bold rounded-md w-max' }, { children: pickRunDetails.statusId === 'PrCompleted'
                                                                ? 'Completed'
                                                                : pickRunDetails.statusId === 'PrInProgress'
                                                                    ? 'In Progress'
                                                                    : ((_b = pickRunDetails.statusId) === null || _b === void 0 ? void 0 : _b.includes('Cancel'))
                                                                        ? 'Cancelled'
                                                                        : 'Ready' }), void 0) }), void 0))] }), void 0)), pickRunDetails.cartId && (_jsxs("div", __assign({ className: 'flex flex-row items-center my-1' }, { children: [_jsx("h1", __assign({ className: 'font-semibold text-black text-base mr-5 w-3/5' }, { children: "Cart" }), void 0), _jsx("h1", __assign({ className: 'font-medium w-3/5' }, { children: pickRunDetails.cartId }), void 0)] }), void 0)), pickRunDetails.pickRunTypeId && (_jsxs("div", __assign({ className: 'flex flex-row items-center my-1' }, { children: [_jsx("h1", __assign({ className: 'font-semibold text-black text-base mr-5 w-3/5' }, { children: "Picking Type" }), void 0), _jsx("h1", __assign({ className: 'font-medium w-3/5' }, { children: pickRunDetails.pickRunTypeId === 'PrtSingle'
                                                            ? 'Single'
                                                            : pickRunDetails.pickRunTypeId === 'PrtOrderLevel'
                                                                ? 'Order Level'
                                                                : pickRunDetails.pickRunTypeId === 'PrtBulkSingle'
                                                                    ? 'Bulk Single'
                                                                    : '' }), void 0)] }), void 0))] }), void 0) }), void 0) }), void 0), (pickRunDetails === null || pickRunDetails === void 0 ? void 0 : pickRunDetails.items) && (pickRunDetails === null || pickRunDetails === void 0 ? void 0 : pickRunDetails.items.length) > 0 && (_jsx(Table, { columns: lookupTableColumnsForPickRunItems, dataSource: pickRunDetails === null || pickRunDetails === void 0 ? void 0 : pickRunDetails.items, isPaginated: false }, 'pickRunId')), Object.keys(pickRunDetails).length <= 0 ||
                                (!((_c = pickRunDetails === null || pickRunDetails === void 0 ? void 0 : pickRunDetails.statusId) === null || _c === void 0 ? void 0 : _c.includes('InProgress')) &&
                                    !((_d = pickRunDetails === null || pickRunDetails === void 0 ? void 0 : pickRunDetails.statusId) === null || _d === void 0 ? void 0 : _d.includes('Ready'))) ? null : (_jsx("div", __assign({ className: 'flex flex-row justify-end mt-4' }, { children: _jsx(Button, { buttonText: 'Continue', onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                                        var loggedInUserId, nearestLocationSeqId, pickRunType;
                                        var _a, _b, _c, _d, _e;
                                        return __generator(this, function (_f) {
                                            switch (_f.label) {
                                                case 0:
                                                    loggedInUserId = localStorage.getItem('userId');
                                                    if (!(!(pickRunDetails === null || pickRunDetails === void 0 ? void 0 : pickRunDetails.assignedToUserId) ||
                                                        (pickRunDetails === null || pickRunDetails === void 0 ? void 0 : pickRunDetails.assignedToUserId) === loggedInUserId)) return [3 /*break*/, 2];
                                                    nearestLocationSeqId = (pickRunDetails === null || pickRunDetails === void 0 ? void 0 : pickRunDetails.items) &&
                                                        ((_a = pickRunDetails === null || pickRunDetails === void 0 ? void 0 : pickRunDetails.items) === null || _a === void 0 ? void 0 : _a.length) > 0 &&
                                                        ((_c = (_b = pickRunDetails === null || pickRunDetails === void 0 ? void 0 : pickRunDetails.items) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.locationSeqId)
                                                        ? (_e = (_d = pickRunDetails === null || pickRunDetails === void 0 ? void 0 : pickRunDetails.items) === null || _d === void 0 ? void 0 : _d[0]) === null || _e === void 0 ? void 0 : _e.locationSeqId
                                                        : '';
                                                    pickRunType = (pickRunDetails === null || pickRunDetails === void 0 ? void 0 : pickRunDetails.pickRunTypeId)
                                                        ? pickRunDetails === null || pickRunDetails === void 0 ? void 0 : pickRunDetails.pickRunTypeId
                                                        : '';
                                                    return [4 /*yield*/, dispatch(getPickRunDetails({
                                                            setErrorMsgText: setPickRunErrorMsgText,
                                                            setScannedLocationIncorrect: setScannedPickRunIdIncorrect,
                                                            nearestLocationSeqId: nearestLocationSeqId,
                                                            fromLookup: true,
                                                            history: history,
                                                            pickRunType: pickRunType,
                                                            setLookUpVisible: setLookUpVisible,
                                                            fromPickRunsScreen: true
                                                        }))];
                                                case 1:
                                                    _f.sent();
                                                    return [3 /*break*/, 3];
                                                case 2:
                                                    notification['error']({
                                                        message: 'Error',
                                                        description: 'Picking already in progress!'
                                                    });
                                                    setLookUpVisible(false);
                                                    _f.label = 3;
                                                case 3: return [2 /*return*/];
                                            }
                                        });
                                    }); } }, void 0) }), void 0))] }), void 0)) }), void 0) }), void 0), _jsx(MainHeading, { heading: 'Pick By Shipment' }, void 0), _jsx(Form, __assign({ form: pickRunForm, className: 'w-full sm:w-4/5 md:w-3/5 lg:w-1/2 mt-6' }, { children: _jsx(Form.Item, __assign({ validateStatus: scannedPickRunIdIncorrect ? 'error' : '', help: scannedPickRunIdIncorrect && pickRunErrorMsgText, className: 'w-full', name: 'scanPickRunId' }, { children: _jsx(Input, { placeholder: '', title: 'Enter Shipment Id To Look Up', onKeyDown: onPickRunIdSubmit, value: pickRunId, onChange: function (e) { return setPickRunId(e.target.value); }, autoFocus: true }, void 0) }), void 0) }), void 0)] }), void 0));
};
export default PickShipment;
