var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
function Button(_a) {
    var onClick = _a.onClick, buttonText = _a.buttonText, isDisabled = _a.isDisabled, widthFull = _a.widthFull, defaultColor = _a.defaultColor, paddingX = _a.paddingX, paddingY = _a.paddingY, classNames = _a.classNames, _b = _a.type, type = _b === void 0 ? 'submit' : _b;
    return (_jsx("button", __assign({ onClick: onClick, disabled: isDisabled, type: type, className: isDisabled
            ? (widthFull && 'w-full') + " " + (paddingX !== null && paddingX !== void 0 ? paddingX : 'px-8') + "\n             text-xs sm:" + paddingX + " sm:text-sm md:text-sm bg-transparent focus:outline-none border border-gray-400 text-gray-400 font-semibold " + (paddingY !== null && paddingY !== void 0 ? paddingY : "py-2") + " rounded-lg " + classNames
            : (widthFull && 'w-full') + " " + (paddingX !== null && paddingX !== void 0 ? paddingX : 'px-8') + "\n             text-xs sm:" + paddingX + " sm:text-sm md:text-sm " + defaultColor + " hover:shadow-custom-btn hover:text-btn-text-hover focus:outline-none text-white font-semibold " + (paddingY !== null && paddingY !== void 0 ? paddingY : "py-2") + " rounded-lg " + classNames }, { children: buttonText }), void 0));
}
Button.defaultProps = {
    isDisabled: false,
    onClick: function () { },
    defaultColor: 'bg-primary',
    widthFull: false,
    paddingX: 'px-12'
};
export default Button;
