var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
function ScanToteData(_a) {
    var scannedToteData = _a.scannedToteData, toteCounter = _a.toteCounter;
    return (_jsx("div", { children: _jsxs("div", __assign({ className: 'flex flex-row' }, { children: [scannedToteData && _jsx("h1", __assign({ className: 'mx-3 mb-2 text-base' }, { children: toteCounter }), void 0), _jsx("h1", __assign({ className: 'mx-3 mb-2 text-base' }, { children: scannedToteData }), void 0)] }), void 0) }, void 0));
}
export default ScanToteData;
