var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { DatePicker as AntDatePicker, Image } from 'antd';
import calendar_svg from '../images/calendar.svg';
import moment from 'moment';
function DatePicker(_a) {
    var value = _a.value, onChange = _a.onChange, title = _a.title, dateFormat = _a.dateFormat, isTimeShown = _a.isTimeShown, isDateDisabled = _a.isDateDisabled, _b = _a.isDisabled, isDisabled = _b === void 0 ? false : _b, style = _a.style;
    return (_jsxs("div", { children: [_jsx("h1", __assign({ className: 'font-bold text-xs mb-0.5 sm:mb-1.5' }, { children: title }), void 0), _jsx("div", __assign({ className: 'border border-gray-400 px-2 py-0.5 rounded-md hover:border-primary-light focus:border-primary-light' }, { children: _jsx(AntDatePicker, { suffixIcon: _jsx(Image, { width: 16, src: calendar_svg, preview: false }, void 0), defaultValue: value, format: dateFormat, onChange: onChange, showTime: isTimeShown ? { defaultValue: moment('00:00:00', 'HH:MM:SS') } : isTimeShown, disabledDate: isDateDisabled
                        ? function (current) {
                            var customDate = moment().format('YYYY-MM-DD, hh:mm A');
                            return current && current < moment(customDate, 'YYYY-MM-DD, hh:mm A');
                        }
                        : undefined, disabled: isDisabled, style: style }, void 0) }), void 0)] }, void 0));
}
DatePicker.defaultProps = {
    value: '',
    dateFormat: 'YYYY-MM-DD',
    isTimeShown: false,
    isDateDisabled: false
};
export default DatePicker;
