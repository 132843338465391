var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Input as AntInput, Popover } from 'antd';
import { InfoCircleFilled, CloseOutlined } from '@ant-design/icons';
function Input(_a) {
    var value = _a.value, onChange = _a.onChange, placeholder = _a.placeholder, title = _a.title, suffix = _a.suffix, hasInfoIcon = _a.hasInfoIcon, showDot = _a.showDot, inputType = _a.inputType, multiline = _a.multiline, isPassword = _a.isPassword, isDisabled = _a.isDisabled, onKeyDown = _a.onKeyDown, autoFocus = _a.autoFocus, onClick = _a.onClick, clearInput = _a.clearInput, onBlur = _a.onBlur, fromHandheld = _a.fromHandheld, allowClear = _a.allowClear, inputMode = _a.inputMode;
    var suffixClass = 'suffix-inp border border-gray-400 px-2 py-2 w-full rounded-md focus:outline-none hover:border-primary-light focus:border-primary-light';
    var reqFieldClassPassword = 'custom-inp border border-gray-400 px-2 py-2 w-full rounded-md focus:outline-none hover:border-primary-light focus:border-primary-light text-primary font-mono';
    var reqFieldClass = 'custom-inp border border-gray-400 px-2 py-2 w-full rounded-md focus:outline-none hover:border-primary-light focus:border-primary-light';
    var normalFieldClass = 'border border-gray-400 px-2 py-2 w-full rounded-md focus:outline-none hover:border-primary-light focus:border-primary-light';
    return (_jsxs("div", { children: [_jsxs("div", __assign({ className: 'flex items-center mb-0.5 sm:mb-1.5' }, { children: [_jsx("h1", __assign({ className: "font-bold " + (fromHandheld ? 'text-sm' : 'text-xs') }, { children: title }), void 0), hasInfoIcon && (_jsx(Popover, __assign({ content: _jsx("p", __assign({ className: 'text-xs' }, { children: "This product is not available in all sales channels. Try this for next steps." }), void 0), placement: 'rightTop' }, { children: _jsx(InfoCircleFilled, { style: { fontSize: '15px', marginLeft: '7px' } }, void 0) }), void 0))] }), void 0), _jsxs("div", __assign({ className: 'flex relative' }, { children: [multiline ? (_jsx("textarea", { onWheelCapture: function (e) {
                            e.target.blur();
                        }, disabled: isDisabled, placeholder: placeholder, value: value, onChange: onChange, rows: 4, cols: 60, className: showDot ? reqFieldClass : normalFieldClass, onKeyDown: onKeyDown, autoFocus: autoFocus, onClick: onClick }, void 0)) : (_jsx(AntInput, { onWheelCapture: function (e) {
                            e.target.blur();
                        }, onBlur: onBlur, disabled: isDisabled, type: inputType, placeholder: placeholder, value: value, defaultValue: value, onChange: onChange, suffix: suffix, onKeyDown: onKeyDown, autoFocus: autoFocus, onClick: onClick, inputMode: inputMode, className: showDot
                            ? isPassword
                                ? reqFieldClassPassword
                                : reqFieldClass
                            : suffix !== ''
                                ? suffixClass
                                : normalFieldClass, allowClear: allowClear
                            ? {
                                clearIcon: _jsx(CloseOutlined, { style: { fontSize: '15px', color: '#ff4d4f' } }, void 0)
                            }
                            : false }, void 0)), showDot && _jsx("div", { className: 'reqDot' }, void 0)] }), void 0)] }, void 0));
}
Input.defaultProps = {
    showDot: true,
    inputType: 'text',
    multiline: false,
    hasInfoIcon: false,
    isPassword: false,
    suffix: '',
    isDisabled: false,
    onKeyDown: function () { },
    autoFocus: false,
    onBlur: function () { },
    onCancelClick: function () { },
    allowClear: false,
    fromHandheld: false
};
export default Input;
