var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { Spin } from 'antd';
import LoadingOverlay from 'react-loading-overlay-ts';
import { useHistory } from 'react-router-dom';
import Sidebar from '../components/Sidebar';
// import TestPrinter from '../components/TestPrinter'
import Language from '../components/ChangeLanguage';
import UserPreference from '../components/UserPreference';
var SettingScreen = function () {
    var _a = useState(false), isLoading = _a[0], setIsLoading = _a[1];
    var history = useHistory();
    useEffect(function () {
        var isAuth = localStorage.getItem('loggedInHH');
        if (!isAuth) {
            history.push('/');
        }
    }, [history]);
    return (_jsx(LoadingOverlay, __assign({ active: isLoading, spinner: _jsx(Spin, {}, void 0) }, { children: _jsx(Sidebar, __assign({ setIsLoading: setIsLoading }, { children: _jsxs("div", __assign({ className: 'md:flex justify-center mt-4 md:mt-16' }, { children: [_jsx(Language, { setIsLoading: setIsLoading }, void 0), _jsx(UserPreference, { setIsLoading: setIsLoading }, void 0)] }), void 0) }), void 0) }), void 0));
};
export default SettingScreen;
