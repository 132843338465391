var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useState } from 'react';
import Sidebar from '../components/Sidebar';
import PickSingle from '../components/PickSingle';
import LoadingOverlay from 'react-loading-overlay-ts';
import { Spin } from 'antd';
var SinglePickScreen = function (props) {
    var subscreenToBeSet = '';
    var currentPickRunTypeId = '';
    var skipScanLocationScreen = false;
    var totesList = [];
    if (props && props.location && props.location.state && props.location.state.subscreenToBeSet) {
        subscreenToBeSet = props.location.state.subscreenToBeSet;
    }
    if (props &&
        props.location &&
        props.location.state &&
        props.location.state.currentPickRunTypeId) {
        currentPickRunTypeId = props.location.state.currentPickRunTypeId;
    }
    if (props &&
        props.location &&
        props.location.state &&
        props.location.state.skipScanLocationScreen) {
        skipScanLocationScreen = props.location.state.skipScanLocationScreen;
    }
    if (props &&
        props.location &&
        props.location.state &&
        props.location.state.totesList &&
        props.location.state.totesList.length > 0) {
        totesList = props.location.state.totesList;
    }
    var _a = useState(false), isLoading = _a[0], setIsLoading = _a[1];
    return (_jsx(LoadingOverlay, __assign({ active: isLoading, spinner: _jsx(Spin, {}, void 0) }, { children: _jsx(Sidebar, __assign({ setIsLoading: setIsLoading }, { children: _jsx(PickSingle, { subscreenToBeSet: subscreenToBeSet, currentPickRunTypeId: currentPickRunTypeId, scannedTotesList: totesList, skipScanLocationScreen: skipScanLocationScreen, setIsLoading: setIsLoading }, void 0) }), void 0) }), void 0));
};
export default SinglePickScreen;
