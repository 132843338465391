var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useState, useEffect } from 'react';
import { Spin } from 'antd';
import LoadingOverlay from 'react-loading-overlay-ts';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Login from '../components/Login';
function LoginScreen() {
    var _a = useState(false), isLoading = _a[0], setIsLoading = _a[1];
    var history = useHistory();
    var dispatch = useDispatch();
    useEffect(function () {
        var isAuth = localStorage.getItem('loggedInHH');
        if (isAuth) {
            history.push('/');
        }
        else {
            history.push('/login');
        }
    }, [dispatch, history]);
    return (_jsx(LoadingOverlay, __assign({ active: isLoading, spinner: _jsx(Spin, {}, void 0) }, { children: _jsx("div", __assign({ className: 'px-8 sm:px-16 md:px-24 lg:px-40 py-6 md:py-14 lg:py-16 bg-neu-bg bg-cover' }, { children: _jsx(Login, { setIsLoading: setIsLoading }, void 0) }), void 0) }), void 0));
}
export default LoginScreen;
