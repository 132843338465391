var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { ImCross } from 'react-icons/im';
var CancelBtn = function (_a) {
    var onBtnClick = _a.onBtnClick, title = _a.title;
    return (_jsxs("div", __assign({ className: 'flex flex-row items-center mt-1.5 w-max cursor-pointer', onClick: onBtnClick }, { children: [_jsx(ImCross, { style: { color: '#e11f20' }, size: 15 }, void 0), _jsx("h1", __assign({ className: 'ml-1.5 font-semibold text-base', style: { color: '#e11f20' } }, { children: title }), void 0)] }), void 0));
};
export default CancelBtn;
