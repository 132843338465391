var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useState } from 'react';
import Sidebar from '../components/Sidebar';
import LoadingOverlay from 'react-loading-overlay-ts';
import { Spin } from 'antd';
import Return from '../components/Return';
var ReturnScreen = function () {
    var _a = useState(false), isLoading = _a[0], setIsLoading = _a[1];
    return (_jsx(LoadingOverlay, __assign({ active: isLoading, spinner: _jsx(Spin, {}, void 0) }, { children: _jsx(Sidebar, __assign({ setIsLoading: setIsLoading }, { children: _jsx(Return, { setIsLoading: setIsLoading }, void 0) }), void 0) }), void 0));
};
export default ReturnScreen;
