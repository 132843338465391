var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createElement as _createElement } from "react";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import axios from 'axios';
import { notification } from 'antd';
import { HashRouter as Router, Route, Switch, useHistory } from 'react-router-dom';
import LoginScreen from './screens/LoginScreen';
import TaskSelectScreen from './screens/TaskSelectScreen';
import PickMultisScreen from './screens/PickMultisScreen';
import PickSingleScreen from './screens/SinglePickScreen';
import MoveToSortingScreen from './screens/MoveToSortingScreen';
import SortingScreen from './screens/SortingScreen';
import ReceivingScreen from './screens/ReceivingScreen';
import MoveAssetScreen from './screens/MoveAssetScreen';
import SettingScreen from './screens/SettingScreen';
import PickRunsScreen from './screens/PickRunsScreen';
import i18n from './i18n';
import PickByShipmentScreen from './screens/PickByShipment';
import PackingScreen from './screens/PackingScreen';
import ReturnScreen from './screens/ReturnScreen';
import PutAwayScreen from './screens/PutAwayScreen';
var SUPPORTED_LANGUAGES = ['en', 'es'];
axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
axios.defaults.withCredentials = true;
window.onunload = function () {
    localStorage.removeItem('currScreen');
    localStorage.removeItem('goToLocationData');
    localStorage.removeItem('pickRunItemId');
    localStorage.removeItem('currentTotalPickRunItemQty');
    localStorage.removeItem('currIndexInPickRunDetailsArr');
    localStorage.removeItem('scannedContainerList');
    localStorage.removeItem('pickRunContainerList');
    localStorage.removeItem('pickRunId');
    localStorage.removeItem('cartId');
    localStorage.removeItem('containerId');
    localStorage.removeItem('containerIdStr');
    localStorage.removeItem('currIndexInPodDetailsList');
    localStorage.removeItem('scannedPodItemsList');
    localStorage.removeItem('scannedContainerIds');
    localStorage.removeItem('podsDetailsArray');
    localStorage.removeItem('currProdId');
    localStorage.removeItem('visitedPickRunItemQty');
    localStorage.removeItem('recommendedContainer');
    localStorage.removeItem('requiredPickContainerCount');
    localStorage.removeItem('currentPodLocationSeqId');
    localStorage.removeItem('currentWorkEffortId');
    localStorage.removeItem('workEffortIdsList');
    localStorage.removeItem('picklistContainerCounts');
    localStorage.removeItem('getContainerDetailsAPIStatus');
    localStorage.removeItem('fromLocation');
    localStorage.removeItem('toLocation');
    localStorage.removeItem('containerDetailNotification');
    localStorage.removeItem('pickedItemsArr');
    localStorage.removeItem('currentOrderId');
    localStorage.removeItem('orderContainerList');
    localStorage.removeItem('isConfirmDropoffAborted');
    localStorage.removeItem('packingStationContainers');
    localStorage.removeItem('hospitalContainers');
    localStorage.removeItem('scannedOrderIds');
    localStorage.removeItem('newCartScannedId');
    localStorage.removeItem('completeLocationData');
    localStorage.removeItem('pickMoveLocationSeqId');
    localStorage.removeItem('itemsCountData');
};
function App() {
    var history = useHistory();
    useEffect(function () {
        window.addEventListener('beforeunload', handleBeforeUnload);
        return function () {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []); // eslint-disable-line
    useEffect(function () {
        var storedLanguage = localStorage.getItem('i18nextLng');
        if (!storedLanguage)
            return;
        if (!SUPPORTED_LANGUAGES.includes(storedLanguage)) {
            localStorage.setItem('i18nextLng', 'en');
            i18n.changeLanguage('en');
        }
        else {
            i18n.changeLanguage(storedLanguage);
        }
    }, []);
    var handleBeforeUnload = function (e) {
        if (!window.location.href.split('/')[3] ||
            window.location.href.split('/')[3] === 'login' ||
            window.location.href.split('/')[3] === 'receiving' ||
            window.location.href.split('/')[3] === 'moveAsset' ||
            window.location.href.split('/')[3] === 'pickMultis') {
            // do nothing
        }
        else {
            e.preventDefault();
            var message = 'Are you sure you want to leave? All provided data will be lost.';
            e.returnValue = message;
            return message;
        }
    };
    axios.interceptors.response.use(function (response) {
        return response;
    }, function (error) {
        // console.log(error)
        // return
        var originalRequest = error.config;
        var currUrl = originalRequest.url.split('/');
        currUrl = currUrl[currUrl.length - 1];
        if (currUrl.includes('lookupPickRun') && error.response.status === 400) {
            // Ignore error notification
        }
        else if (currUrl === 'facility' && error.response.status === 400) {
            localStorage.clear();
            history.push('/login');
            // window.location.replace('/login')
        }
        else if (error.response && error.response.status === 401) {
            var errorMsg = '';
            if (typeof error.response.data === 'string') {
                errorMsg = error.response.data;
            }
            else if (error.response.data.errors) {
                errorMsg = error.response.data.errors;
            }
            if (errorMsg.includes('Session token does not match') ||
                errorMsg.includes('Session token required')) {
                // Renew session token
                return axios.get('/rest/s1/fp/moquiSessionToken').then(function (resp) {
                    if (resp.status === 200) {
                        localStorage.setItem('moquiSessionToken', resp.data.moquiSessionToken);
                        originalRequest.headers['moquiSessionToken'] = resp.data.moquiSessionToken;
                        return axios(originalRequest);
                    }
                });
            }
            else if (errorMsg.includes('User must be logged in')) {
                // User is not logged in, redirect to login page
                localStorage.clear();
                history.push('/login');
                // window.location.replace('/login')
            }
        }
        else if (error.response && error.response.status === 403) {
            var errorMsg = '';
            if (typeof error.response.data === 'string') {
                errorMsg = error.response.data;
            }
            else if (error.response.data.errors) {
                errorMsg = error.response.data.errors;
            }
            if (errorMsg.includes('is not authorized for View')) {
                // User is not logged in, redirect to login page
                localStorage.clear();
                window.location.replace('/#/login');
            }
        }
        else if (error.message === 'Network Error') {
            notification['error']({
                message: 'Error',
                description: 'Network Error'
            });
            return;
        }
        else if (error.response && error.response.data && error.response.data.errorCode === 400) {
            var capturedError = "Container " + currUrl + " is empty";
            var errMsg = '';
            if (typeof error.response.data === 'string') {
                errMsg = error.response.data;
            }
            else if (error.response.data.errors) {
                errMsg = error.response.data.errors;
            }
            if (errMsg.includes(capturedError)) {
                localStorage.setItem('getContainerDetailsAPIStatus', 'status_400');
            }
            else if (errMsg.includes('Cannot set preference with key ACTIVE_ORGANIZATION')) {
                notification['error']({
                    message: 'Error',
                    description: errMsg
                });
                localStorage.clear();
                window.location.replace('/#/login');
            }
            else {
                var containerDetailNotificationDisable = localStorage.getItem('containerDetailNotification');
                containerDetailNotificationDisable = JSON.parse(containerDetailNotificationDisable);
                if (!containerDetailNotificationDisable ||
                    errMsg.includes('No items is available for pick') ||
                    errMsg.includes('No item is available for pick')) {
                    notification['error']({
                        message: 'Error',
                        description: errMsg
                    });
                    if (errMsg.includes('No item is available for pick') ||
                        errMsg.includes('No items is available for pick')) {
                        setTimeout(function () {
                            window.location.replace('/');
                        }, 1500);
                    }
                }
            }
            return;
        }
        else {
            var errMsg = error.response && error.response.data && error.response.data.errors
                ? error.response.data.errors
                : 'Connection Refused';
            notification['error']({
                message: 'Error',
                description: errMsg
            });
            return;
        }
    });
    return (_jsx(Router, { children: _jsxs(Switch, { children: [_jsx(Route, { path: '/moveAsset', component: MoveAssetScreen }, void 0), _jsx(Route, { path: '/pickMultis', component: PickMultisScreen }, void 0), _jsx(Route, { path: '/pickSingle', render: function (props) { return _createElement(PickSingleScreen, __assign({}, props, { key: Date.now() })); } }, void 0), _jsx(Route, { path: '/sorting', component: SortingScreen }, void 0), _jsx(Route, { path: '/login', component: LoginScreen }, void 0), _jsx(Route, { path: '/receiving', component: ReceivingScreen }, void 0), _jsx(Route, { path: '/return', component: ReturnScreen }, void 0), _jsx(Route, { path: '/moveToSorting', component: MoveToSortingScreen }, void 0), _jsx(Route, { path: '/settings', component: SettingScreen }, void 0), _jsx(Route, { path: '/picking', component: PickRunsScreen }, void 0), _jsx(Route, { path: '/pickShipment', component: PickByShipmentScreen }, void 0), _jsx(Route, { path: '/packing', component: PackingScreen }, void 0), _jsx(Route, { path: '/putaway', component: PutAwayScreen }, void 0), _jsx(Route, { exact: true, path: '/', component: TaskSelectScreen }, void 0)] }, void 0) }, void 0));
}
export default App;
