var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
var Heading = function (_a) {
    var data = _a.data, label = _a.label;
    return (_jsxs("div", __assign({ className: 'mb-2 flex flex-row items-center' }, { children: [_jsx("h1", __assign({ className: 'text-sm font-semibold text-gray-400 w-1/2' }, { children: label }), void 0), _jsx("h1", __assign({ className: 'w-1/2 font-medium text-base' }, { children: data }), void 0)] }), void 0));
};
export default Heading;
