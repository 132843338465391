var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Modal as AntModal, Spin } from 'antd';
import LoadingOverlay from 'react-loading-overlay-ts';
var LookUp = function (_a) {
    var title = _a.title, visible = _a.visible, onCancel = _a.onCancel, children = _a.children, width = _a.width, _b = _a.isLoading, isLoading = _b === void 0 ? false : _b, _c = _a.isGlobalLookup, isGlobalLookup = _c === void 0 ? false : _c;
    return (_jsx(AntModal, __assign({ title: _jsxs(_Fragment, { children: [title, ' ', !isGlobalLookup && _jsx("h1", __assign({ className: 'text-xs' }, { children: localStorage.getItem('activeOrgName') }), void 0)] }, void 0), visible: visible, footer: null, onCancel: onCancel, width: width }, { children: _jsx(LoadingOverlay, __assign({ active: isLoading, spinner: _jsx(Spin, {}, void 0), styles: {
                overlay: {
                    backgroundColor: ''
                },
                wrapper: {},
                content: {},
                spinner: {}
            } }, { children: children }), void 0) }), void 0));
};
LookUp.defaultProps = {
    width: '33%'
};
export default LookUp;
