var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
var SelectBtn = function (_a) {
    var text = _a.text, isDisabled = _a.isDisabled, onClick = _a.onClick, pointerDisabled = _a.pointerDisabled;
    return (_jsx("div", { children: !isDisabled ? (_jsx("div", __assign({ onClick: onClick, className: "flex flex-col items-center py-2 mb-3.5 border rounded-md cursor-pointer " + (isDisabled ? 'border-gray-400' : 'border-gray-600') }, { children: _jsx("h1", __assign({ className: (isDisabled ? 'text-gray-400' : 'text-gray-800') + " font-semibold" }, { children: text }), void 0) }), void 0)) : (_jsx("div", __assign({ className: "flex flex-col items-center py-2 mb-3.5 border rounded-md " + (pointerDisabled && 'cursor-pointer') + " " + (isDisabled ? 'border-gray-400' : 'border-gray-600') }, { children: _jsx("h1", __assign({ className: (isDisabled ? 'text-gray-400' : 'text-gray-600') + " font-semibold" }, { children: text }), void 0) }), void 0)) }, void 0));
};
SelectBtn.defaultProps = {
    onClick: function () { }
};
export default SelectBtn;
