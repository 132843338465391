var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { Modal as AntModal } from 'antd';
var Modal = function (_a) {
    var title = _a.title, visible = _a.visible, onCancel = _a.onCancel, children = _a.children, width = _a.width;
    return (_jsx(AntModal, __assign({ title: title, visible: visible, footer: null, onCancel: onCancel, width: width }, { children: children }), void 0));
};
Modal.defaultProps = {
    width: '45%'
};
export default Modal;
