var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { IoIosAddCircle } from 'react-icons/io';
import { MdCheckCircle } from 'react-icons/md';
function Totes(_a) {
    var toteData = _a.toteData, counter = _a.counter;
    return (_jsx("div", { children: _jsxs("div", __assign({ className: 'flex flex-row' }, { children: [!toteData ? (_jsx(IoIosAddCircle, { className: 'text-2xl', style: { color: '#ED693B' } }, void 0)) : (_jsx(MdCheckCircle, { className: 'text-2xl', style: { color: '#58BB55' } }, void 0)), _jsxs("h1", __assign({ className: 'mx-3 mb-2' }, { children: ["Tote ", counter] }), void 0), _jsx("h1", __assign({ className: 'mx-3 mb-2' }, { children: toteData }), void 0)] }), void 0) }, void 0));
}
export default Totes;
