var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Upload, notification } from "antd";
import { CloudUploadOutlined, InboxOutlined } from "@ant-design/icons";
function UploadImageFile(_a) {
    var _this = this;
    var setFile = _a.setFile, isCloudIconVisible = _a.isCloudIconVisible, _b = _a.showUploadList, showUploadList = _b === void 0 ? true : _b, _c = _a.setShowUploadList, setShowUploadList = _c === void 0 ? function () { } : _c;
    var Dragger = Upload.Dragger;
    var props = {
        name: "file",
        accept: "image/*",
        maxCount: 1,
        progress: {
            strokeColor: {
                "0%": "#108ee9",
                "100%": "#87d068",
            },
            strokeWidth: 3,
            format: function (percent) { return parseFloat(percent.toFixed(2)) + "%"; },
        },
        beforeUpload: function () { return false; },
        onChange: function (info) {
            var _a = info.file, status = _a.status, size = _a.size;
            var fileSize = size / 1024 / 1024 < 0.5; // 500kb
            if (!fileSize) {
                notification["error"]({
                    message: "Error",
                    description: info.file.name + " file must be less than 500kb.",
                });
                return;
            }
            setFile(info.file);
            setShowUploadList(true);
            if (status === "done") {
                notification["success"]({
                    message: "Success",
                    description: info.file.name + " file uploaded successfully.",
                });
            }
            else if (status === "error") {
                notification["error"]({
                    message: "Error",
                    description: info.file.name + " file upload failed.",
                });
            }
        },
        onRemove: function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                setFile(null);
                return [2 /*return*/];
            });
        }); },
    };
    return (_jsxs(Dragger, __assign({}, props, { className: "" + (!isCloudIconVisible && "pt-5 pb-10 "), showUploadList: showUploadList }, { children: [isCloudIconVisible ? (_jsx("p", __assign({ className: "ant-upload-drag-icon" }, { children: _jsx(CloudUploadOutlined, {}, void 0) }), void 0)) : (_jsx(InboxOutlined, { style: {
                    fontSize: "35px",
                    color: "#727272",
                    marginTop: "17px",
                    marginBottom: "10px",
                } }, void 0)), _jsxs("p", __assign({ className: "ant-upload-text" }, { children: ["Click or drag file to this area", " ", !isCloudIconVisible && _jsx("span", { children: "to Upload" }, void 0)] }), void 0), isCloudIconVisible && (_jsx("p", __assign({ className: "ant-upload-hint" }, { children: "Supported files: 500kb or less, PNG, JPEG, JPG" }), void 0))] }), void 0));
}
export default UploadImageFile;
